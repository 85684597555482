import React, { useEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';

import CriiptoVerifyProviderHelper from './criipto-verify-provider';
import FinlandSelector from './finland-selector/App';
import AuthMethodSelector from './auth-method-selector/App';
import DanishMitID from './DanishMitID';
import SwedishBankID from './SwedishBankID';
import DutchIdin from './DutchIdin';

import { Action, Bootstrap, ClientConfiguration, Scenario, ViewVersion } from './renditions';
import { onReactRender, parseAction } from './utils';

import './screen-entry.css';
import ScreenRouter, { useScreen } from './screen-router';
import { GlobalDOM } from './global-dom';

export type CustomUIComponent = ((props: {
  children: React.ReactNode,
  action: Action
  language: string
}) => React.ReactElement | null)

function Routes(props: {
  viewVersion: ViewVersion
  clientConfiguration: ClientConfiguration
  scenario: Scenario,
  customUIComponent: CustomUIComponent
}) {
  const {screen, rawRequestUrl, request} = useScreen();
  const {viewVersion, clientConfiguration} = props;

  const inner = (
    <React.Fragment>
      {screen.screen === "FinnishTelia/Select" ? (
        <FinlandSelector
          viewVersion={viewVersion}
          model={screen.rendition}
        />
      ) : screen.screen === "AuthMethodSelector" ? (
        <CriiptoVerifyProviderHelper
          clientConfiguration={clientConfiguration}
          requestUrl={rawRequestUrl}
          domain={(new URL(rawRequestUrl)).host}
        >
          <AuthMethodSelector
            clientConfiguration={clientConfiguration}
            requestUrl={rawRequestUrl}
            viewVersion={viewVersion}
            model={screen.rendition}
          />
        </CriiptoVerifyProviderHelper>
      ) : screen.screen === 'DanishMitID/CvrSelect' ? (
        <DanishMitID.CvrSelect
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'DanishMitID/CprEntry' ? (
        <DanishMitID.CprEntry
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'DanishMitID/BrokerLandingPage' ? (
        <DanishMitID.BrokerLandingPage
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
          errorStrategy={clientConfiguration.error_strategy}
        />
      ) : screen.screen === 'DanishMitID/Continue' ? (
        <DanishMitID.Continue
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'DanishMitID/Error' ? (
        <DanishMitID.Error
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'SwedishBankID/Error' ? (
        <SwedishBankID.Error
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'SwedishBankID/Cancel' ? (
        <SwedishBankID.Cancel
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'SwedishBankID/AnotherDeviceSSN' ? (
        <SwedishBankID.AnotherDeviceSSN
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'SwedishBankID/AnotherDeviceQr' ? (
        <SwedishBankID.AnotherDeviceQr
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'SwedishBankID/StartApp' ? (
        <SwedishBankID.StartApp
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : screen.screen === 'SwedishBankID/Collect' ? (
        <SwedishBankID.Collect
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
          userAgent={navigator.userAgent}
          allowAutoLaunch={true}
        />
      ) : screen.screen === 'DutchIdin/SelectBank' ? (
        <DutchIdin.SelectBank
          requestUrl={new URL(rawRequestUrl)}
          viewVersion={viewVersion}
          model={screen.rendition}
          action={parseAction(request?.login_hint) ?? 'login'}
        />
      ) : assertUnreachableScreen(screen)}
    </React.Fragment>
  )

  if (props.customUIComponent) {
    let CustomUIComponent = props.customUIComponent;
    return (
      <CustomUIComponent
        action={parseAction(request?.login_hint) ?? 'login'}
        language={request.ui_locales}
      >
        <GlobalDOM {...props} domain={window.location.hostname} screen={screen.screen} />
        {inner}
      </CustomUIComponent>
    )
  }

  return (
    <React.Fragment>
      <GlobalDOM {...props} domain={window.location.hostname} screen={screen.screen} />
      {inner}
    </React.Fragment>
  );
}

onReactRender(async function (element: HTMLElement | null) {
  const container = element ?? document.getElementById('criipto_react_root')!;
  const bootstrap : Bootstrap =
    document.getElementById('criipto_react_root')!.getAttribute('data-bootstrap') &&
    JSON.parse(document.getElementById('criipto_react_root')!.getAttribute('data-bootstrap')!);
  const root = createRoot(container);

  const customUIComponent =
    bootstrap.ui.customUIScript ?
      await import(/* webpackIgnore: true */ bootstrap.ui.customUIScript).then(module => module.default ?? module) : 
      null;
  
  root.render(
    <React.Fragment>
      <ScreenRouter initial={bootstrap}>
        <Routes
          viewVersion={bootstrap.viewVersion}
          scenario={bootstrap.scenario}
          clientConfiguration={bootstrap.clientConfiguration}
          customUIComponent={customUIComponent}
        />
      </ScreenRouter>
    </React.Fragment>
  );
});

function assertUnreachableScreen(x: never): never {
  throw new Error(`Unandled screen: ${JSON.stringify(x)}`);
}